import React from "react"

import SEO from "../components/seo"

const NotFoundPage = () => (
  <div>
    <SEO title="404: Siden finnes ikke." />
    <h2>Denne siden finnes desverre ikke, prøv gjerne å gjøre et søk.</h2>
  </div>
)

export default NotFoundPage
